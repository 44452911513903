import { get } from "../utils/http"

function getBanners(size) {
    const url = "/v1/banners?size=" + size;
    return get({ url });
}

function getActivityWellchosen(size) {
    const url = "/v1/activity-well-chosen?size=" + size;
    return get({ url });
}

function getWellchosen(size) {
    const url = "/v1/well-chosen?size=" + size;
    return get({ url });
}

export default {
    getBanners,
    getActivityWellchosen,
    getWellchosen
}