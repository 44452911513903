<template>
  <div class="index home">
    <van-skeleton title :row="3" :loading="loading" >
      <div class="search-bar">
      <van-row>
        <van-col span="6">
          <van-dropdown-menu>
          <van-dropdown-item v-model="cityId" :options="cities">
            <template #title>
              <van-icon name="location-o" color="#2E6DC8" /> {{getCityName()}}
            </template>
          </van-dropdown-item>
        </van-dropdown-menu>
        </van-col>
        <van-col span="17" offset="1">
          <van-search placeholder="搜索权益" />
        </van-col>
      </van-row>
      </div>
    </van-skeleton>

    <van-skeleton title :row="6" :loading="loading" >
      <div class="key-banner banner" v-if="banners.length > 0">
        <van-swipe :autoplay="3000">
          <van-swipe-item v-for="banner in banners" :key="banner.id"  @click="onGoLink(banner)">
            <van-image width="100%" height="176px" fit="cover" :src="banner.imageUrl" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <van-notice-bar
        v-if="notificationContent"
        left-icon="volume-o"
        :text="notificationContent"
      />
      <div class="sub-key-banner banner" v-if="wellchosenActivities.length > 0">
        <van-swipe :autoplay="4000">
          <van-swipe-item v-for="act in wellchosenActivities" :key="act.id"  @click="onBuyLink(act)">
            <van-image width="100%" height="137px" fit="cover" :src="act.imageUrl" />
          </van-swipe-item>
        </van-swipe>
      </div>
    </van-skeleton>

    <van-skeleton title :row="4" :loading="loading" >
      <h1>权益精选</h1>
      <van-grid column-num="2" :border="false">
        <van-grid-item v-for="chosen in wellchosens" :key="chosen.id">
          <!-- <img
            style="width: 100%; height: 90px; border-radius: 10px 10px 0 0"
            :src="chosen.imageUrl"
          /> -->
          <van-image width="100%" height="90px" fit="cover" :src="chosen.imageUrl" />

          <div class="item-content">
            <div class="item-title">
              <div class="item-name">{{ chosen.title }}</div>
              <div class="item-tag"></div>
            </div>
            <div class="item-action" @click="onGoLink(chosen)">{{chosen.linkName}} ></div>
          </div>
        </van-grid-item>
      </van-grid>
     </van-skeleton>
  </div>

  <Nav active="home"></Nav>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import Nav from "../components/Nav.vue";
import { useRouter } from "vue-router";
import Controller from "./home-controller";
import "../css/home.scss";

export default {
  components: {
    Nav,
  },
  setup() {
    const state = reactive({
      banners: [],
      wellchosens: [],
      wellchosenActivities: [],
      notifications: [],
      notificationContent: "",
      cityId: 0,
      cities: [],
      loading: true,
    });
    const router =  useRouter();
    onMounted(() => Controller.onMounted(state, router));
    const onGoLink = (wellchosen) => {
      let {linkType , link } = Controller.getLink(wellchosen);
      if( linkType == 'link') {
        document.location.href = link;
      } else {
        router.push(link);
      }
    };
    const onBuyLink = act => router.push("/dish/" + act.objectId);
    const getCityName = () => Controller.getCityName(state);
    return {
      ...toRefs(state),
      onGoLink,
      onBuyLink,
      getCityName,
    };
  },
};
</script>
