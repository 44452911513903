import WellchosenService from "../api/wellchosen-service";
import DataService from "../api/basic-data-service";
import User from "../api/user";
import Member from "../api/member";
import { buildOptions, getOption } from "../utils/index";
import { useRoute } from "vue-router";

const getLink = wellchosen => {
    if (wellchosen.linkType == 'restaurant') {
        return {
            link: "/restaurant/" + wellchosen.url,
        }
    } else if (wellchosen.linkType == 'restaurant-booking') {
        return {
            link: "/restaurant/" + wellchosen.url + "/booking",
        }
    } else if (wellchosen.linkType == 'dish') {
        return {
            link: "/dish/" + wellchosen.url,
        }
    } else if (wellchosen.linkType == 'buy-dish') {
        return {
            link: "/dish/" + wellchosen.url,
        }
    } else if (wellchosen.linkType == 'link') {
        return {
            link: wellchosen.url,
            linkType: "link"
        }
    }
    return {
        link: wellchosen.url,
        linkType: "link"
    };
}
const onLoad = state => {
    WellchosenService.getBanners(4).then(results => {
        state.banners = results;
        state.loading = false;
    });
    WellchosenService.getWellchosen(6).then(results => {
        state.wellchosens = results;
        state.loading = false;
    });
    WellchosenService.getActivityWellchosen(4).then(results => {
        state.wellchosenActivities = results;
        state.loading = false;
    });
    DataService.getNotifications().then(results => {
        state.notifications = results;
        state.notificationContent = results.length > 0 ? results[0].title : "";
        state.loading = false;
    });
    DataService.getCities().then(results => {
        state.cities =
            buildOptions(results, "城市", 0, (c) => ({ value: c.id, text: c.name }));
        state.loading = false;
    });
}

const onMounted = (state, router) => {
    const { code, client, cardProductName, cardLevel, cardBin, cardId, userId } = useRoute().query;
    const data = { code, client, cardProductName, cardLevel, cardBin, cardId, userId };
    User.login(data)
        .then(() => Member.getMemberInfo(), () => User.notFound(router))
        .then(() => onLoad(state), () => User.notFound(router));
}

const getCityName = (state) => getOption(state.cities, state.cityId) || "城市"

export default {
    getLink,
    onMounted,
    getCityName
}